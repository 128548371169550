<template>
    <div class="home">
        <div class="header-banner">
            <img class="img" src="@/assets/home/geom_banner.jpg" />
        </div>
        <div class="body">
            <div v-if="loading">
                <van-loading type="spinner" vertical style="margin:8rem 0">加载中</van-loading>
            </div>
            <div v-else>
                <div v-if="devList.length > 2">
                    <div class="block-line-part" v-for="(item,index) in devList" :key="index">
                        <div class="position-area" v-if="index % 2 == 0">{{item.devName}}</div>
                        <div class="inner-area" v-else>
                            <div :class="{'box-info':true,'online':item.devStatus === 'online','isCar': item.parkChange && item.devStatus === 'online' ? item.parkChange.parkChange === 1 : false}">
                                <div class="name">{{ item.devCode }}</div>
                                <div><span>设备S N：</span></div>
                                <div>{{ item.contactId }}</div>
                                <div><span>设备状态：</span>{{item.devStatus === 'online' ? '在线' : '离线'}}</div>
                                <div><span>停车状态：</span>{{item.parkChange ? item.parkChange.parkChange === 1?'有车' :'无车' : '无车'}}</div>
                                <div><span>设备电量：</span> {{item.parkChange ? item.parkChange.batValue: '0'}}%</div>
                                <div class="time">
                                    {{ 
                                        item.parkChange ? 
                                        item.parkChange.uploadTime
                                        : item.updateTime || '0000-00-00 00:00:00'
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="line-area"></div>
                        <div class="inner-area"  v-if="index % 2 == 0">
                            <div :class="{'box-info':true,'online':item.devStatus === 'online','isCar': item.parkChange && item.devStatus === 'online'  ? item.parkChange.parkChange === 1 : false}">
                                <div class="name">{{ item.devCode }}</div>
                                <div><span>设备S N：</span></div>
                                <div>{{ item.contactId }}</div>
                                <div><span>设备状态：</span>{{item.devStatus === 'online' ? '在线' : '离线'}}</div>
                                <div><span>停车状态：</span>{{item.parkChange ? item.parkChange.parkChange === 1?'有车' :'无车' : '无车'}}</div>
                                <div><span>设备电量：</span> {{item.parkChange ? item.parkChange.batValue: '0'}}%</div>
                                <div class="time">
                                    {{ 
                                        item.parkChange ? 
                                        item.parkChange.uploadTime
                                        : item.updateTime || '0000-00-00 00:00:00'
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="position-area" v-else>
                            {{item.devName}}
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div :class="{'block-inline-row':true,'online':item.devStatus ==='online','isCar': item.parkChange && item.devStatus === 'online'  ? item.parkChange.parkChange === 1 : false}" 
                        v-for="(item,index) in devList" :key="index">
                        <div class="top">
                            <div class="name">{{item.devName}}</div>
                            <div class="name">{{item.devCode}}</div>
                        </div>
                        <div class="middle">
                            <div><span>设备S N：</span>{{item.contactId}}</div>
                            <div><span>设备状态：</span>{{item.devStatus === 'online' ? '在线' : '离线'}}</div>
                            <div><span>停车状态：</span>{{item.parkChange ? item.parkChange.parkChange === 1?'有车' :'无车' : '无车'}}</div>
                            <div><span>设备电量：</span> {{item.parkChange ? item.parkChange.batValue: '0'}}%</div>
                        </div>
                        <div class="bottom">
                            <div class="time">
                                {{ 
                                    item.parkChange ? 
                                    item.parkChange.uploadTime
                                    : item.updateTime || '0000-00-00 00:00:00'
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <tabbar />
    </div>
</template>

<script>
import tabbar from '@/components/geom/tabBar.vue';
import moment from 'moment'
const mqtt = require('mqtt');
import MQTT_OPTIONS from '@/config/mqtt.js'

export default {
    components:{
        tabbar
    },
    data(){
        return{
            devList:[],
            count:{
                online:0,
                offline:0,
                alarm:0,
            },
            projectCode:null,
            loading:true,
            client:null
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.getHomeList();
    },
    beforeDestroy(){
        this.client ? this.client.end() : void 0;
    },
    methods:{
        getHomeList(){
            this.$api.GEOM.getDevParkChange({
                projectCode:this.projectCode
            }).then( d => {
                this.devList = d;
                this.loading = false;
                this.mqttMsg();
            })
        },
        mqttMsg(){
            this.client  = mqtt.connect('ws://47.101.147.59:8083/mqtt',MQTT_OPTIONS);
            this.client.on('connect', (e) => {
                console.log(e, "MQTT连接成功！！！");
                const topic = `/sudp/sync/geom/${this.projectCode}/+/park/get`
                this.client.subscribe(topic, {}, (error) => {  // qos 为通道
                    if (!error) {
                        console.log('消息订阅成功',topic)
                    } else {
                        console.log('消息订阅失败')
                    }
                })
            })
            // 接收消息处理
            this.client.on('message', (topic, message) => {
                const devInfo = JSON.parse(message.toString());
                this.matchDevInfo(devInfo);
            })
            // 断开发起重连
            this.client.on('reconnect', (error) => {
                console.log('正在重连:', error)
            })
            // 链接异常处理
            this.client.on('error', (error) => {
                console.log('连接失败:', error)
            })
        },
        // 匹配更改数组
        matchDevInfo(devInfo){
            this.devList.forEach( e => {
                if(e.devCode === devInfo.devCode){
                    e.devStatus = 'online';
                    e.parkChange.batValue = devInfo.batValue;
                    e.parkChange.parkChange = devInfo.parkChange;
                    e.parkChange.uploadTime = moment(devInfo.uploadTime).format('YYYY-MM-DD HH:mm:ss');
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.home{
    .header{
        display: flex;
        padding: 10px;
        justify-content: space-around;
        .block-box{
            background: #3574a0;
            color: #fff;
            width: 30%;
            padding: 10px 0;
            border-radius: 10px;
            line-height: 1.5;
            box-shadow: 2px 3px 6px #a5a5a5;
            .num{
                font-size: 1.5rem;
                font-weight: bolder;
            }
        }
    }
    .header-banner{
        .img{
            width: 100%;
        }
    }
    .body{
        padding: 10px;
        padding-bottom: 22%;
        .block-line-part{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 175px;
            .position-area{
                width: 49%;
                font-weight: bolder;
            }
            .line-area{
                width: 2%;
                height: 175px;
                background: #dedede;
                position: relative;
                &::after{
                    position: absolute;
                    top: 48%;
                    left: -10%;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #34a53c;
                    content:' ';
                }
            }
            .inner-area{
                width: calc(49% - 40px);
                padding: 10px 20px;
                font-size:.85rem;
                .box-info{
                    border: 1px solid #ccc;
                    background: #a5a5a5;
                    color: #fff;
                    border-radius: 10px;
                    text-align: left;
                    box-shadow: 2px 3px 6px #c1c1c1;
                    padding: 15px 12px;
                    div{
                        padding-bottom: 4px;
                    }
                    span{
                        color: #353535;
                    }
                    .name{
                        color: #fff;
                        font-weight: bolder;
                    }
                    .time{
                        color: #353535;
                        font-size: .75rem;
                    }
                }
            }
        }
    }

    .block-inline-row{
        text-align: left;
        background-color: #a5a5a5;
        border-radius: 10px;
        color: #fff;
        font-size: .85rem;
        margin-bottom: 10px;
        .top{
            display: flex;
            justify-content: space-between;
            padding: 10px;
            font-weight: bolder;
            border-bottom: 1px solid #fff;
            color: #fff;
            font-size: .95rem;
        }
        .middle{
            padding: 10px;
            span{
                color: #353535;
            }
            div{
                padding-bottom: 5px;
            }
        }
        .time{
            color: #353535;
            text-align: right;
        }
        .bottom{
            padding: 10px;
            border-top: 1px solid #fff;
        }
    }


    .online{
        background-color: #a7d07b !important;
    }
    .isCar{
        background: #34a53c !important;
    }
}
</style>